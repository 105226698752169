import { ISearchItem } from 'shared/ui/input-search/input-search';
import { Field, useForm, useFormState } from 'react-final-form';
import { Chip, Chips, FormField, Skeleton } from '@indriver/nova';
import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { sendAnalyticsEvent } from 'shared/lib/send-analytics-event';
import { toBeRequired } from 'common/lib';
import { ArrowLeftRight } from '@indriver/mireska';
import { variables } from '@indriver/yrel';
import { useTranslation } from 'next-i18next';
import useWindowSize from 'shared/hooks/useWindowSize';
import { IRecPrice, IRecPriceRequest } from 'common/api';
import addMinutes from 'date-fns/addMinutes';
import { roundToNearest15Minutes } from 'shared/lib/round-to-nearest-15-minutes';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import dynamic from 'next/dynamic';
import getConfig from 'next/config';
import RoundClose from 'shared/ui/round-close';
import useIsRTL from 'shared/hooks/useIsRTL';
import useOnClickOutside from 'shared/hooks/useOnClickOutside';
import HintRecommendedPrice from 'features/hint-recommended-price';
import RideWithTollsModal from 'features/ride-with-tolls/ride-with-tolls-modal';
import { ABTogglesContext } from 'context/ab-toggles';
import { Experiments } from 'common/api/ab';
import StickyButton from 'widgets/static-order-form/ui/sticky-button';
import * as Sentry from '@sentry/nextjs';
import { GeoWrapper } from 'context/geo';
import {
    ChipItemStyle,
    ChipsDivider,
    ChipsStyle,
    ChipsWrapper,
    DatePickerInputWrapper,
    InputWrapper,
    MobileDataPickerWrapper,
    PassengerCountInputWrapper,
    StyledButton,
    StyledOrderWrapper,
} from './order-form.style';
import { IMinMaxPrices, IOrderValues } from '../static-order-form';
import { OrderInputNumber } from './order-input-number';
import { InputCounter } from './input-counter';
import { MobileInputCounter } from './mobile-input-counter';
import { OrderInput } from './order-input';
import { HintRadioButton } from './hint-radio-button';

const DatePickerMobile = dynamic(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    async () =>
        import('@indriver/nova').then((module) => module.DatePickerMobile).catch((e) => Sentry.captureException(e)),
    {
        loading: () => <Skeleton height='56px' />,
        ssr: false,
    },
);
const InputDatepicker = dynamic(async () => import('shared/ui/input-datepicker'), {
    loading: () => <Skeleton height='56px' />,
    ssr: false,
});
const MobileInputSearch = dynamic(async () => import('shared/ui/mobile-input-search'), {
    loading: () => <Skeleton height='56px' />,
    ssr: false,
});

const InputSearch = dynamic(async () => import('shared/ui/input-search'), {
    loading: () => <Skeleton height='56px' />,
    ssr: false,
});

interface IProps {
    autofilledCities: { fromCity: ISearchItem; toCity: ISearchItem } | null;
    minMaxPrice: Record<string, IMinMaxPrices> | null;
    getMinMaxPrice: (city_id: string | number, city_to_id?: string | number | null) => Promise<void>;
    getRecPrice: IRecPriceRequest;
    getCityAutocomplete: (search: string) => Promise<ISearchItem[]>;
    handleSubmit: () => void;
    isLoading: boolean;
}

const { publicRuntimeConfig = {} } = getConfig();

export const WrappedForm: FC<IProps> = ({
    handleSubmit,
    autofilledCities,
    minMaxPrice,
    getMinMaxPrice,
    getRecPrice,
    isLoading,
    getCityAutocomplete,
}) => {
    const form = useForm();
    const { values } = useFormState<IOrderValues>();
    const [visibleCounter, setVisibleCounter] = useState(false);
    const { width } = useWindowSize();
    const ref = useRef<HTMLDivElement>(null);
    const formRef = useRef<HTMLFormElement>(null);
    const { t, i18n } = useTranslation();
    const { direction } = useIsRTL();
    const [recPrice, setRecPrice] = useState<IRecPrice | null>(null);
    const { toggles } = useContext(ABTogglesContext);
    const tollsExperiment = toggles?.experiments?.[Experiments.INTERCITY3_WEB_TOLLS];
    const geo = useContext(GeoWrapper);

    useOnClickOutside(ref, () => width >= 521 && setVisibleCounter(false), 'mouseup');

    useEffect(() => {
        if (autofilledCities?.fromCity?.value && autofilledCities.toCity?.value) {
            form.change('city_from_id', autofilledCities.fromCity);
            form.change('city_to_id', autofilledCities.toCity);
            form.change('currency_code', autofilledCities.fromCity?.data?.currency_code);
            form.change('timezone', autofilledCities.fromCity?.data?.timezone);

            const dateTimeField = form.getFieldState('departure_time')?.value;
            const minimalDatetime = utcToZonedTime(
                addMinutes(roundToNearest15Minutes(new Date()), 15),
                autofilledCities.fromCity?.data?.timezone,
            );

            const currentDate = roundToNearest15Minutes(dateTimeField);
            const selectedDate = new Date(minimalDatetime);

            if (currentDate.getTime() < selectedDate.getTime()) {
                form.change('departure_time', minimalDatetime);
            }
            getMinMaxPrice(autofilledCities.fromCity?.value, autofilledCities.toCity?.value)
                .then((r) => r)
                .catch((e) => Sentry.captureException(e));
        }
    }, [autofilledCities?.fromCity?.value, autofilledCities?.toCity?.value]);

    const cityFrom = form.getFieldState('city_from_id')?.value as ISearchItem;
    const cityTo = form.getFieldState('city_to_id')?.value as ISearchItem;
    const radioType = form.getFieldState('type')?.value as 'pool' | 'private';

    const handleUpdateRecPrice = async () => {
        const isAvailableCountry = publicRuntimeConfig.REC_PRICE_AVAILABLES_COUNTRIES.some(
            (countryId: number) => countryId === cityFrom?.data?.country_id,
        );

        if (!isAvailableCountry) {
            setRecPrice(null);
            form.change('price', '');
            return;
        }
        if (!(radioType === 'private' && values.passengers_count > 1 && !!recPrice?.private_price)) {
            if (cityFrom?.value && cityTo?.value && values.passengers_count) {
                const res = await getRecPrice(cityFrom?.value, cityTo?.value, values.passengers_count);
                const prices = res as IRecPrice;
                if (radioType === 'pool') {
                    if (prices?.pool_price !== 0) {
                        form.change('price', prices.pool_price);
                        sendAnalyticsEvent('web.intercity.pass.rec_price_autoinsert', {
                            // city_id: getItemFromStorage('X-City-Id'),
                            from_city_id: cityFrom.value,
                            to_city_id: cityTo.value,
                            rec_price: prices.pool_price,
                            order_type: 'pool',
                            passengers_count: values.passengers_count,
                        });
                    }
                } else if (prices?.private_price !== 0) {
                    form.change('price', prices.private_price);
                    sendAnalyticsEvent('web.intercity.pass.rec_price_autoinsert', {
                        // city_id: getItemFromStorage('X-City-Id'),
                        from_city_id: cityFrom.value,
                        to_city_id: cityTo.value,
                        rec_price: prices.private_price,
                        order_type: 'private',
                        passengers_count: values.passengers_count,
                    });
                }
                setRecPrice(prices);
            }
        }
    };
    useEffect(() => {
        handleUpdateRecPrice()
            .then((r) => r)
            .catch((e) => Sentry.captureException(e));
    }, [cityFrom?.value, radioType, cityTo?.value, values.passengers_count]);

    const recPriceValue = useMemo(() => recPrice?.[`${radioType}_price`], [radioType, recPrice]);

    useEffect(() => {
        // const recPriceValue = recPrice?.[`${radioType}_price`];
        if (recPriceValue) {
            form.change('price', recPriceValue);
        }
        if (recPriceValue === 0) {
            form.change('price', '');
        }
    }, [radioType, recPrice]);

    const [openedModalTolls, setOpenedModalTolls] = useState(false);

    // Логика показа модалки с толлами если выбраны А и Б
    // useEffect(() => {
    //     if (!!cityFrom?.data && !!cityTo?.data && cityFrom?.data?.country_id === cityTo?.data?.country_id) {
    //         // if (!!cityFrom?.data && !!cityTo?.data && tollsExperiment?.before_click_find) {
    //         // setOpenedModalTolls(true);
    //     }
    // }, [cityFrom?.value, cityTo?.value]);
    return (
        <form ref={formRef} onSubmit={handleSubmit}>
            <ChipsWrapper>
                <Field name='type'>
                    {(props) => {
                        return (
                            <FormField dir={direction} invalid={false} role='group' style={{ flexDirection: 'row' }}>
                                <Chips
                                    dir={direction}
                                    multiple={false}
                                    active={[props.input.value]}
                                    size='l'
                                    inline
                                    className={ChipsStyle}
                                    onChange={(id) => {
                                        if (id && id?.length) {
                                            const [value] = id;
                                            props.input.onChange(value);
                                            sendAnalyticsEvent('web.intercity.passenger.full_car.click', {
                                                event_value: value === 'private',
                                            });
                                        }
                                    }}>
                                    <Chip
                                        id='private'
                                        className={ChipItemStyle}
                                        role='button'
                                        postfix={<HintRadioButton />}
                                        active={props.input.value === 'private'}>
                                        {t('order.without_fellow_travelers')}
                                    </Chip>
                                    <Chip
                                        id='pool'
                                        className={ChipItemStyle}
                                        role='button'
                                        active={props.input.value === 'pool'}>
                                        {t('order.with_fellow_travelers')}
                                    </Chip>
                                </Chips>
                            </FormField>
                        );
                    }}
                </Field>
                {geo?.code === 'IN' && width > 820 && (
                    <>
                        <ChipsDivider />
                        <Field name='trip_type'>
                            {(props) => {
                                return (
                                    <FormField
                                        dir={direction}
                                        invalid={false}
                                        role='group'
                                        style={{ flexDirection: 'row' }}>
                                        <Chips
                                            dir={direction}
                                            multiple={false}
                                            active={[props.input.value]}
                                            size='l'
                                            inline
                                            className={ChipsStyle}
                                            onChange={(id) => {
                                                if (id && id?.length) {
                                                    const [value] = id;
                                                    form.change('return_time', null);
                                                    props.input.onChange(value);
                                                    sendAnalyticsEvent('web_intercity_psg_round_trip_change', {
                                                        event_value: value,
                                                    });
                                                }
                                            }}>
                                            <Chip
                                                id='one_way'
                                                className={ChipItemStyle}
                                                role='button'
                                                active={props.input.value === 'one_way'}>
                                                {t('order.one_way')}
                                            </Chip>
                                            <Chip
                                                id='round_trip'
                                                className={ChipItemStyle}
                                                role='button'
                                                active={props.input.value === 'round_trip'}>
                                                {t('order.round_trip')}
                                            </Chip>
                                        </Chips>
                                    </FormField>
                                );
                            }}
                        </Field>
                    </>
                )}
            </ChipsWrapper>
            <StyledOrderWrapper dir={direction} withReturnDate={values.trip_type === 'round_trip'}>
                <Field
                    name='city_from_id'
                    validate={(value: ISearchItem) => toBeRequired(value?.value, t('order.input_city'))}>
                    {(props) => {
                        return (
                            <FormField
                                dir={direction}
                                className={InputWrapper}
                                invalid={props.meta.touched ? !!props.meta.error : false}
                                hint={props.meta.error && props.meta.touched && props.meta.error}
                                onFocus={() => sendAnalyticsEvent('web.intercity.passenger.from.click')}>
                                {width <= 520 ? (
                                    <MobileInputSearch
                                        dir={direction}
                                        selectedItem={props.input.value as unknown as ISearchItem}
                                        placeholder={t('order.from')}
                                        loadData={getCityAutocomplete}
                                        changeBtn={
                                            props.input.value || cityTo ? (
                                                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                                <div
                                                    tabIndex={-1}
                                                    role='button'
                                                    onClick={() => form.mutators.changeCities()}>
                                                    <ArrowLeftRight
                                                        size={24}
                                                        color={variables['text-and-icon-primary']}
                                                    />
                                                </div>
                                            ) : null
                                        }
                                        onSelect={async (item) => {
                                            if (item) {
                                                form.change('timezone', item.data.timezone);
                                                props.input.onChange(item);
                                                sendAnalyticsEvent('web.intercity.passenger.from_done', {
                                                    event_value: item.label,
                                                });
                                                if (item?.data.currency_code) {
                                                    form.change('currency_code', item.data.currency_code);
                                                }
                                                await getMinMaxPrice(item.value, cityTo?.value);
                                            } else {
                                                form.change('currency_code', '');
                                                props.input.onChange(null);
                                            }
                                        }}
                                    />
                                ) : (
                                    <InputSearch
                                        dir={direction}
                                        selectedItem={props.input.value as unknown as ISearchItem}
                                        placeholder={t('order.from')}
                                        loadData={getCityAutocomplete}
                                        changeBtn={
                                            props.input.value || cityTo ? (
                                                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                                <div
                                                    style={{ cursor: 'pointer' }}
                                                    tabIndex={-1}
                                                    role='button'
                                                    onClick={() => form.mutators.changeCities()}>
                                                    <ArrowLeftRight
                                                        size={24}
                                                        color={variables['text-and-icon-primary']}
                                                    />
                                                </div>
                                            ) : null
                                        }
                                        onSelect={async (item) => {
                                            if (item) {
                                                const dateTimeField = form.getFieldState('departure_time')?.value;
                                                const minimalDatetime = utcToZonedTime(
                                                    addMinutes(roundToNearest15Minutes(new Date()), 15),
                                                    item.data.timezone,
                                                );

                                                const currentDate = roundToNearest15Minutes(dateTimeField);
                                                const selectedDate = new Date(minimalDatetime);

                                                if (currentDate.getTime() < selectedDate.getTime()) {
                                                    form.change('departure_time', minimalDatetime);
                                                }
                                                props.input.onChange(item);
                                                form.change('timezone', item.data.timezone);

                                                sendAnalyticsEvent('web.intercity.passenger.from_done', {
                                                    event_value: item.label,
                                                });
                                                if (item?.data.currency_code) {
                                                    form.change('currency_code', item.data.currency_code);
                                                }
                                                await getMinMaxPrice(item.value, cityTo?.value);
                                            } else {
                                                form.change('currency_code', '');
                                                props.input.onChange(null);
                                            }
                                        }}
                                    />
                                )}
                            </FormField>
                        );
                    }}
                </Field>
                <Field
                    name='city_to_id'
                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                    /* //@ts-ignore */
                    validate={(value: ISearchItem, state?: object) => {
                        if (
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            state?.city_from_id &&
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            state?.city_to_id &&
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            state?.city_from_id.value === state?.city_to_id.value
                        ) {
                            return t('order.select_different_cities');
                        }

                        return toBeRequired(value?.value, t('order.input_city'));
                    }}>
                    {(props) => {
                        return (
                            <FormField
                                dir={direction}
                                className={InputWrapper}
                                invalid={props.meta.touched ? !!props.meta.error : false}
                                hint={props.meta.error && props.meta.touched && props.meta.error}
                                onFocus={() => sendAnalyticsEvent('web.intercity.passenger.to.click')}>
                                {width <= 520 ? (
                                    <MobileInputSearch
                                        dir={direction}
                                        selectedItem={props.input.value as unknown as ISearchItem}
                                        placeholder={t('order.to')}
                                        loadData={getCityAutocomplete}
                                        onSelect={async (item) => {
                                            if (item) {
                                                props.input.onChange(item);
                                                sendAnalyticsEvent('web.intercity.passenger.to_done', {
                                                    event_value: item.label,
                                                });
                                                await getMinMaxPrice(cityFrom?.value, item.value);
                                            } else {
                                                props.input.onChange(null);
                                            }
                                        }}
                                    />
                                ) : (
                                    <InputSearch
                                        dir={direction}
                                        selectedItem={props.input.value as unknown as ISearchItem}
                                        placeholder={t('order.to')}
                                        loadData={getCityAutocomplete}
                                        onSelect={async (item) => {
                                            if (item) {
                                                props.input.onChange(item);
                                                sendAnalyticsEvent('web.intercity.passenger.to_done', {
                                                    event_value: item.label,
                                                });
                                                await getMinMaxPrice(cityFrom?.value, item.value);
                                            } else {
                                                props.input.onChange(null);
                                            }
                                        }}
                                    />
                                )}
                            </FormField>
                        );
                    }}
                </Field>
                {geo?.code === 'IN' && width <= 820 && (
                    <>
                        <Field name='trip_type'>
                            {(props) => {
                                return (
                                    <FormField
                                        dir={direction}
                                        invalid={false}
                                        role='group'
                                        style={{ flexDirection: 'row' }}>
                                        <Chips
                                            dir={direction}
                                            multiple={false}
                                            active={[props.input.value]}
                                            size='l'
                                            inline
                                            className={ChipsStyle}
                                            onChange={(id) => {
                                                if (id && id?.length) {
                                                    const [value] = id;
                                                    form.change('return_time', null);
                                                    props.input.onChange(value);
                                                    sendAnalyticsEvent('web_intercity_psg_round_trip_change', {
                                                        event_value: value,
                                                    });
                                                }
                                            }}>
                                            <Chip
                                                id='one_way'
                                                className={ChipItemStyle}
                                                role='button'
                                                active={props.input.value === 'one_way'}>
                                                {t('order.one_way')}
                                            </Chip>
                                            <Chip
                                                id='round_trip'
                                                className={ChipItemStyle}
                                                role='button'
                                                active={props.input.value === 'round_trip'}>
                                                {t('order.round_trip')}
                                            </Chip>
                                        </Chips>
                                    </FormField>
                                );
                            }}
                        </Field>
                    </>
                )}
                <Field name='departure_time'>
                    {(props) => {
                        return (
                            // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
                            <FormField id='#date_wrapper' dir={direction} className={DatePickerInputWrapper}>
                                {width <= 520 ? (
                                    <MobileDataPickerWrapper
                                        dir={direction}
                                        id='mobileDatePicker'
                                        onClick={(e) => {
                                            const target = e.target as HTMLDivElement;
                                            if (
                                                target.parentElement !== null &&
                                                target.parentElement.id === 'mobileDatePicker'
                                            ) {
                                                sendAnalyticsEvent('web.intercity.passenger.dep_date.click');
                                            }
                                        }}>
                                        <DatePickerMobile
                                            value={props.input.value}
                                            selectedDateFormat={
                                                i18n.language.includes('en')
                                                    ? 'eee, dd MMM, HH:mm'
                                                    : 'eeeeee, dd MMM, HH:mm'
                                            }
                                            mode={[
                                                {
                                                    type: 'day',
                                                    relativeFormat: { other: 'EEEE, dd MMMM' },
                                                },
                                                { type: 'time', timeFormat: '24h' },
                                            ]}
                                            name='date'
                                            title={t('order.when')}
                                            locale={i18n.language}
                                            CustomCell={({ onClick, ...dateProps }) => (
                                                <span>
                                                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                                    {/* @ts-ignore */}
                                                    <OrderInput
                                                        {...dateProps}
                                                        isInputButton
                                                        dir={direction}
                                                        onClickCell={onClick}
                                                    />
                                                </span>
                                            )}
                                            rootSelector='#mobile-city-root'
                                            position='fixed'
                                            onChange={(value) => {
                                                props.input.onChange(value);
                                                sendAnalyticsEvent('web.intercity.passenger.dep_date_done', {
                                                    event_value: value,
                                                });
                                            }}
                                        />
                                    </MobileDataPickerWrapper>
                                ) : (
                                    <InputDatepicker
                                        dir={direction}
                                        date={props.input.value}
                                        timeZone={cityFrom?.data?.timezone}
                                        placeholder={t('order.when')}
                                        onInputClick={() =>
                                            sendAnalyticsEvent('web.intercity.passenger.dep_date.click')
                                        }
                                        onChange={(value) => {
                                            props.input.onChange(value);
                                        }}
                                    />
                                )}
                            </FormField>
                        );
                    }}
                </Field>
                {values.trip_type === 'round_trip' && (
                    <Field name='return_time'>
                        {(props) => {
                            return (
                                // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
                                <FormField id='#return_date_wrapper' dir={direction} className={DatePickerInputWrapper}>
                                    {width <= 520 ? (
                                        <MobileDataPickerWrapper
                                            dir={direction}
                                            id='mobileDatePickerReturn'
                                            onClick={(e) => {
                                                const target = e.target as HTMLDivElement;
                                                if (
                                                    target.parentElement !== null &&
                                                    target.parentElement.id === 'mobileDatePickerReturn'
                                                ) {
                                                    sendAnalyticsEvent('web_intercity_psg_return_date_click');
                                                }
                                            }}>
                                            <DatePickerMobile
                                                dir={direction}
                                                value={props.input.value || new Date()}
                                                selectedDateFormat={
                                                    i18n.language.includes('en')
                                                        ? 'eee, dd MMM, HH:mm'
                                                        : 'eeeeee, dd MMM, HH:mm'
                                                }
                                                mode={[
                                                    {
                                                        type: 'day',
                                                        relativeFormat: { other: 'EEEE, dd MMMM' },
                                                    },
                                                    { type: 'time', timeFormat: '24h' },
                                                ]}
                                                minuteStep={15}
                                                name='date'
                                                title={t('order.return')}
                                                locale={i18n.language}
                                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                // @ts-ignore
                                                CustomCell={({ onClick, ...dateProps }) => (
                                                    <span>
                                                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                                        {/* @ts-ignore */}
                                                        <OrderInput
                                                            {...dateProps}
                                                            value={props.input.value ? (dateProps.value as string) : ''}
                                                            isInputButton
                                                            // value={dateProps.value}
                                                            placeholder={t('order.return')}
                                                            dir={direction}
                                                            onClickCell={onClick}
                                                        />
                                                    </span>
                                                )}
                                                // CustomCell={(propsDatePicker) => (
                                                //     <OrderInput
                                                //         dir={direction}
                                                //         isInputButton
                                                //         onClick={props.onClick}
                                                //         {...propsDatePicker}
                                                //     />
                                                // )}
                                                rootSelector='#mobile-city-root'
                                                position='fixed'
                                                onChange={(value) => {
                                                    props.input.onChange(value);
                                                    sendAnalyticsEvent('web_intercity_psg_return_date_done', {
                                                        event_value: value,
                                                    });
                                                }}
                                            />
                                        </MobileDataPickerWrapper>
                                    ) : (
                                        <InputDatepicker
                                            dir={direction}
                                            date={props.input.value}
                                            timeZone={cityFrom?.data?.timezone}
                                            placeholder={t('order.return')}
                                            onInputClick={() =>
                                                sendAnalyticsEvent('web_intercity_psg_return_date_click')
                                            }
                                            onChange={(value) => {
                                                props.input.onChange(value);
                                                sendAnalyticsEvent('web_intercity_psg_return_date_done', {
                                                    event_value: value,
                                                });
                                            }}
                                        />
                                    )}
                                </FormField>
                            );
                        }}
                    </Field>
                )}
                <Field name='passengers_count'>
                    {(props) => {
                        return (
                            // <div ref={ref} style={{ position: 'relative' }}>
                            <OrderInput
                                dir={direction}
                                small
                                isInputButton
                                className={PassengerCountInputWrapper}
                                value={String(props.input.value)}
                                placeholder={t('order.passengers')}
                                onClickCell={() => setVisibleCounter(!visibleCounter)}>
                                {width <= 520 ? (
                                    <MobileInputCounter
                                        dir={direction}
                                        inputRef={ref}
                                        visibleCounter={visibleCounter}
                                        setVisibleCounter={setVisibleCounter}
                                        setPassengersCounter={(value) => {
                                            form.change('passengers_count', value);
                                            sendAnalyticsEvent('web.intercity.passenger.pass_count_done', {
                                                event_value: value,
                                            });
                                        }}
                                        value={props.input.value}
                                        radioType={radioType}
                                    />
                                ) : (
                                    <InputCounter
                                        dir={direction}
                                        divRef={ref}
                                        visible={visibleCounter}
                                        value={props.input.value}
                                        onClickPlus={(newValue) => {
                                            form.change('passengers_count', newValue);
                                            sendAnalyticsEvent('web.intercity.passenger.pass_count_done', {
                                                event_value: newValue,
                                            });
                                        }}
                                        onClickMinus={(newValue) => {
                                            form.change('passengers_count', newValue);
                                            sendAnalyticsEvent('web.intercity.passenger.pass_count_done', {
                                                event_value: newValue,
                                            });
                                        }}
                                    />
                                )}
                            </OrderInput>
                            // </div>
                        );
                    }}
                </Field>
                <Field
                    name='price'
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    validate={(value, { type, currency_code }) => {
                        const number = Number(value);
                        if (minMaxPrice?.[type]) {
                            if (value === '' || value === undefined) {
                                return undefined;
                            }
                            if (number < minMaxPrice[type]?.min_price) {
                                return t('order.invalid_min_price', {
                                    minPrice: minMaxPrice[type]?.min_price,
                                    currency: currency_code && `${currency_code}`,
                                });
                            }
                            if (number > minMaxPrice[type]?.max_price) {
                                return t('order.invalid_max_price', {
                                    maxPrice: minMaxPrice[type]?.max_price,
                                    currency: currency_code && `${currency_code}`,
                                });
                            }
                        }

                        return undefined;
                    }}>
                    {(props) => {
                        // const recPriceValue = recPrice?.[`${radioType}_price`];
                        const isRecPrice = Number(props.input.value) === recPriceValue;
                        return (
                            <FormField
                                style={
                                    {
                                        // maxWidth: width <= 640 ? 'none' : 232,
                                        // width: '100%',
                                        // textAlign: 'start',
                                    }
                                }
                                invalid={props.meta.touched ? !!props.meta.error : false}
                                hint={props.meta.error && props.meta.touched ? props.meta.error : null}
                                onFocus={() => sendAnalyticsEvent('web.intercity.passenger.price.click')}
                                onBlur={() => {
                                    sendAnalyticsEvent('web.intercity.passenger.price_done', {
                                        event_value: Number(props.input.value),
                                    });
                                }}>
                                <OrderInputNumber
                                    dir={direction}
                                    last
                                    name='price'
                                    autoComplete='one-time-code'
                                    inputMode='numeric'
                                    suffix={values.currency_code}
                                    value={props.input.value}
                                    placeholder={t(
                                        isRecPrice && !!recPriceValue
                                            ? 'order.recommended_price'
                                            : 'landing.offer_your_fare',
                                    )}
                                    postfix={
                                        props.input.value.length > 0 && (
                                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                tabIndex={-1}
                                                role='button'
                                                onClick={() => {
                                                    form.change('price', undefined);
                                                }}>
                                                <RoundClose size={20} color={variables['text-and-icon-secondary']} />
                                            </div>
                                        )
                                    }
                                    onChange={(event, value) => {
                                        const val = value.value?.replace(/\D/g, '') ?? '';
                                        props.input.onChange(val);
                                    }}
                                />
                                {!(props.meta.error && props.meta.touched) && recPriceValue && (
                                    <HintRecommendedPrice
                                        isRecPrice={isRecPrice}
                                        recPriceValue={recPriceValue}
                                        currency={values.currency_code}
                                        onSetRecPrice={() => {
                                            form.change('price', recPriceValue);
                                            sendAnalyticsEvent('web.intercity.psg.rec_price_click', {
                                                from: cityFrom?.data.name,
                                                to: cityTo?.data.name,
                                                rec_price: recPriceValue,
                                                order_type: radioType,
                                                passengers_count: values.passengers_count,
                                            });
                                        }}
                                    />
                                )}
                            </FormField>
                        );
                    }}
                </Field>

                <Field name='currency_code'>
                    {(props) => {
                        return <input type='hidden' value={props.input.value} />;
                    }}
                </Field>
                <Field name='timezone'>
                    {(props) => {
                        return <input type='hidden' value={props.input.value} />;
                    }}
                </Field>
                <StickyButton
                    type='submit'
                    size='l'
                    text='order.find_driver'
                    className={StyledButton}
                    loading={isLoading}
                    disabled={isLoading}
                    onClick={async (e) => {
                        formRef?.current?.scrollIntoView();
                        const validate = {
                            allFieldsFilled: true,
                            cityFrom: false,
                            cityTo: false,
                            price: false,
                        };

                        validate.price = !values.price;
                        if (!values.city_from_id?.value || !values.city_to_id?.value) {
                            validate.allFieldsFilled = false;
                            validate.cityFrom = !values.city_from_id;
                            validate.cityTo = !values.city_to_id;
                        }

                        sendAnalyticsEvent('web.intercity.passenger.find.click', {
                            is_required_fields_filled: validate.allFieldsFilled,
                            is_missed_from: validate.cityFrom,
                            is_missed_to: validate.cityTo,
                            is_missed_date: false,
                            is_missed_price: validate.price,
                            is_missed_pass_num: false,
                        });
                        if (tollsExperiment?.after_click_find) {
                            e.preventDefault();
                            setOpenedModalTolls(true);
                        }
                    }}
                />
            </StyledOrderWrapper>
            <RideWithTollsModal
                title={recPriceValue ? 'tolls.price_doesnt_include_tolls' : 'tolls.prices_dont_include_tolls'}
                text={recPriceValue ? 'tolls.you_can_change_suggested' : 'tolls.ask_drivers_about_cost'}
                opened={openedModalTolls}
                onContinue={tollsExperiment?.after_click_find ? handleSubmit : () => setOpenedModalTolls(false)}
                onClose={() => setOpenedModalTolls(false)}
            />
        </form>
    );
};
