import { useRouter } from 'next/router';
import { PrefetchOptions } from 'next/dist/shared/lib/router/router';
import { UrlObject } from 'url';

const UTM_PARAMS = ['utm_source', 'utm_medium', 'utm_campaign', 'adposition', 'utm_term'];

type Url = UrlObject | string;

interface ITransitionOptions {
    shallow?: boolean;
    locale?: string | false;
    scroll?: boolean;
    unstable_skipClientCache?: boolean;
}

const getQueryParam = (name: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
};

const addUTMParamsToPath = (path: string) => {
    const url = new URL(path, window.location.href);
    for (const param of UTM_PARAMS) {
        if (url.searchParams.get(param)) {
            continue;
        }
        const value = getQueryParam(param);
        if (value) {
            url.searchParams.append(param, value);
        }
    }

    return url.pathname + url.search;
};
const paramsForUrl = (path: Url) => {
    if (typeof path === 'string') return addUTMParamsToPath(path);
    const { pathname, query } = path;
    const updatedQuery = { ...(query as Record<string, string | number>) };

    for (const param of UTM_PARAMS) {
        if (!updatedQuery.hasOwnProperty(param)) {
            const value = getQueryParam(param);
            if (value) {
                updatedQuery[param] = value;
            }
        }
    }

    return {
        ...path,
        pathname: pathname as string,
        query: updatedQuery,
    };
};
const useUTMRouter = (): ReturnType<typeof useRouter> => {
    const router = useRouter();

    const push = async (path: Url, as?: Url, options?: ITransitionOptions): Promise<boolean> => {
        return router.push(paramsForUrl(path), as, options);
    };

    const replace = async (path: Url, as?: Url, options?: ITransitionOptions): Promise<boolean> =>
        router.replace(paramsForUrl(path), as, options);

    const prefetch = async (path: string, asPath?: string, options?: PrefetchOptions): Promise<void> =>
        router.prefetch(addUTMParamsToPath(path), asPath, options);

    return {
        ...router,
        push,
        replace,
        prefetch,
    };
};

export default useUTMRouter;
