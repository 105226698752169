import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { ISearchItem } from 'shared/ui/input-search/input-search';
import { UseMutationResult } from '@tanstack/react-query';
import { IAutocompleteCity, IOrderForm, IRecPriceRequest, order } from 'common/api';
import { Form } from 'react-final-form';
import { EnumSnackType, useSnacks } from 'shared/hooks/useSnacks';
import { removeItemFromStorage, setItemToStorage } from 'shared/lib/storage';
import { canRefreshToken, CookiesEnum, getRefreshToken, isAuthorizedUser, setCrossDomainCookie } from 'common/lib/auth';
import { AxiosError } from 'axios';
import { sendAnalyticsEvent } from 'shared/lib/send-analytics-event';
import { isMobile } from 'shared/lib/is-mobile';
import { getRecommendedPriceByCity } from 'common/api/order';
import { getCookie, setCookie } from 'cookies-next';
import { logger } from '@indriver/elastic-logger';
import useUTMRouter from 'shared/hooks/use-utm-router';
import addMinutes from 'date-fns/addMinutes';
import { roundToNearest15Minutes } from 'shared/lib/round-to-nearest-15-minutes';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import { GeoWrapper } from 'context/geo';
import { ABTogglesContext } from 'context/ab-toggles';
import { Experiments } from 'common/api/ab';
import { updateUserInfo } from 'common/api/user';
import { useMe } from 'context/user';
import { hashPhoneNumber } from 'shared/lib/hash-phone-number';
import { PreviousURLContext } from 'context/prevRoute';
import format from 'date-fns-tz/format';
import { useTranslation } from 'next-i18next';
import { useResolveLang } from 'shared/lib/date-fns-i18n';
import { TimezoneWrapper } from 'context/timezone';
import { ACTIVE_ORDER_STORAGE_KEY, ROUND_TRIP_INFO_STORAGE_KEY } from 'shared/constants/order';
import { useAnalytics } from 'shared/hooks/use-analytics';
import * as Sentry from '@sentry/nextjs';
import { WrappedForm } from './ui';
interface IProps {
  requestOrder: UseMutationResult<{
    id: number;
  }, AxiosError, IOrderForm, unknown>;
  fromCity?: IAutocompleteCity;
  toCity?: IAutocompleteCity;
  passengers?: number;
  departureTime?: Date;
  rideType?: string;
}
export interface IOrderValues {
  city_from_id: ISearchItem | null;
  city_to_id: ISearchItem | null;
  currency_code: string;
  departure_time: Date;
  type: string;
  trip_type: 'one_way' | 'round_trip';
  return_time: Date | null;
  passengers_count: number;
  // payment_type_id: 0;
  price: number | string;
  timezone: string;
}
export interface IMinMaxPrices {
  max_price: number;
  min_price: number;
  order_type: string;
}
const StaticOrderForm: FC<IProps> = ({
  requestOrder,
  fromCity,
  toCity,
  passengers,
  departureTime,
  rideType
}) => {
  const [minMaxPrice, setMinMaxPrice] = useState<Record<string, IMinMaxPrices> | null>(null);
  const {
    addSnack
  } = useSnacks();
  const router = useUTMRouter();
  const geo = useContext(GeoWrapper);
  const {
    toggles
  } = useContext(ABTogglesContext);
  const cpfExperiment = toggles?.experiments?.[Experiments.INTERCITY3_WEB_CPF_FORM];
  const prevRoute = useContext(PreviousURLContext);
  const {
    requestUserInfo
  } = useMe();
  const {
    t,
    i18n
  } = useTranslation();
  const locale = useResolveLang(i18n.resolvedLanguage);
  const timeZone = useContext(TimezoneWrapper);
  const {
    sendEventBloomreach
  } = useAnalytics();
  const autofilledCities = useMemo(() => fromCity && toCity ? {
    fromCity: {
      label: fromCity.name,
      value: fromCity.id,
      data: fromCity
    },
    toCity: {
      label: toCity.name,
      value: toCity.id,
      data: toCity
    }
  } : null, [fromCity, toCity]);
  const getCityAutocomplete = useCallback(async (search: string): Promise<ISearchItem[]> => {
    const res = await order.getCities(search);
    return res.map((item: IAutocompleteCity) => ({
      value: item.id,
      label: item.name,
      data: item
    }));
  }, []);
  useEffect(() => {
    sendAnalyticsEvent('web.intercity.passenger.main_shown', {
      layout: isMobile() ? 'mobile' : 'desktop',
      page_language: router.locale?.slice(0, 2),
      is_auth: !!getRefreshToken(),
      is_autofilled_from: !!fromCity?.id,
      is_autofilled_to: !!toCity?.id,
      is_autofilled_date: true,
      is_autofilled_price: false,
      is_autofilled_pass_num: true,
      value_autofilled_from: fromCity?.name ?? '',
      value_autofilled_to: toCity?.name ?? '',
      value_autofilled_date: departureTime ?? new Date(),
      value_autofilled_price: null,
      value_autofilled_pass_num: passengers ?? 1,
      value_autofilled_is_pull: true
    });
    const {
      query
    } = router;
    if (query?.source && query?.sub_id) {
      const maxAge = 30 * 24 * 60 * 60; // 90 days in seconds
      const options = {
        maxAge,
        path: '/',
        // domain: 'intercity.indrive.com',
        secure: true // Only set the cookie for HTTPS connections
      };
      setCookie('source', query?.source, options);
      setCookie('sub_id', query?.sub_id, options);
    }
    (async () => {
      await sendEventBloomreach('first_time', {
        role: 'customer',
        platform: 'web'
      }, 'once');
    })().then(r => r).catch(e => Sentry.captureException(e));
  }, []);
  const getRecPrice: IRecPriceRequest = async (from_city_id, to_city_id, passenger_quantity) => {
    try {
      return await getRecommendedPriceByCity(from_city_id, to_city_id, passenger_quantity);
    } catch (e) {
      Sentry.captureException(e);
      logger.error({
        e
      }, 'get-rec-price');
      return e;
    }
  };
  const getMinMaxPrice = async (city_id: string | number, city_to_id?: string | number | null) => {
    try {
      if (city_to_id && !city_id) {
        return;
      }
      const res: IMinMaxPrices[] = await order.getPriceByCity(city_id, city_to_id);
      const data = res.reduce((acc: Record<string, IMinMaxPrices>, item) => {
        acc[item.order_type] = item;
        return acc;
      }, {});
      if (res?.length > 0) {
        setMinMaxPrice(data);
      }
    } catch (e) {
      Sentry.captureException(e);
      if (e instanceof Error) {
        addSnack(e.message, EnumSnackType.failure);
      }
    }
  };
  const getOrdersPassenger = async () => {
    try {
      return await order.getOrder();
    } catch (e) {
      Sentry.captureException(e);
      return null;
    }
  };
  const onSubmit = useCallback(async (values: IOrderValues) => {
    try {
      setCrossDomainCookie(CookiesEnum.referrerPage, '', {
        maxAge: -1
      });
      // const isConsentCookies = getItemFromStorage('showed_cookies');
      // if (!isConsentCookies) {
      //     addSnack('ERROR COOKIES', EnumSnackType.failure);
      //     // Вы не приняли согласие с использованием файлов cookie
      // return;
      // }
      if (values.city_from_id && values.city_to_id) {
        const data: IOrderForm = {
          address_from: values.city_from_id.data.name,
          address_to: values.city_to_id.data.name,
          city_from_id: Number(values.city_from_id?.value),
          city_to_id: Number(values.city_to_id?.value),
          currency_code: values.currency_code,
          // currency_code: currencyCode,
          countries: {
            from_id: values.city_from_id.data.country_id,
            to_id: values.city_to_id.data.country_id
          },
          departure_time: {
            is_detailed: true,
            timestamp: Math.floor(zonedTimeToUtc(values.departure_time, values.city_from_id.data.timezone).getTime() / 1000)
          },
          type: values.type,
          passengers_count: values.passengers_count,
          // 4181 - cash payment
          payment_type_id: 4181,
          price: Number(values.price),
          source_ref_id: (getCookie('sub_id') as string) ?? '',
          source: (getCookie('source') as string) ?? '',
          ...(values.trip_type === 'round_trip' && {
            comment: values.return_time ? t('order.comment_round_trip_with_date', {
              date: format(values.return_time, 'dd MMMM', {
                timeZone,
                locale
              }),
              time: format(values.return_time, 'HH:mm', {
                timeZone,
                locale
              })
            }) : t('order.comment_round_trip_without_date')
          })
        };
        setItemToStorage(ACTIVE_ORDER_STORAGE_KEY, data);
        setItemToStorage('X-City-Id', Number(values.city_from_id?.value));
        setItemToStorage(ROUND_TRIP_INFO_STORAGE_KEY, {
          trip_type: values.trip_type,
          is_round_trip: values.trip_type === 'round_trip',
          return_time: values?.return_time && Math.floor(zonedTimeToUtc(values?.return_time, values.city_from_id.data.timezone).getTime() / 1000)
        });
        requestOrder.reset();
        if (canRefreshToken() || isAuthorizedUser()) {
          // getOrdersPassenger - нужен чтобы получить актуальный accessToken если он истек
          await getOrdersPassenger();
          const userInfo = await requestUserInfo();
          if (!userInfo?.city_id && data?.city_from_id) {
            await updateUserInfo({
              city_id: data?.city_from_id
            });
          }
          // If need cpf than redirect to cpf screen
          if (cpfExperiment?.enabled && !userInfo?.cpf_status && geo?.code === 'BR' && (data?.countries?.from_id === 11 || data?.countries?.to_id === 11)) {
            const cityId = data?.countries.from_id === 11 ? data.city_from_id : data?.countries.to_id === 11 ? data.city_to_id : 1;
            await updateUserInfo({
              city_id: cityId
            });
            await router.push('/auth/cpf', undefined, {
              shallow: true
            });
            // if need input name redirect to name screen
          } else if (!userInfo?.name || userInfo?.name === 'Passenger') {
            await router.push('/auth/name', undefined, {
              shallow: true
            });
          } else {
            await requestOrder.mutateAsync(data);
            const resOrder = await order.getOrder();
            if (resOrder?.id) {
              sendAnalyticsEvent('web.intercity.passenger.bids_feed.view', {
                order_id: resOrder.id,
                user_id: resOrder.user_id,
                order_from: data.address_from,
                order_to: data.address_to,
                order_date: new Date(data.departure_time.timestamp * 1000),
                order_price: data.price,
                order_pass_num: data.passengers_count,
                order_is_pull: data.type === 'pool',
                order_currency: data.currency_code,
                partner_source: (getCookie('source') as string) ?? '',
                partner_ref_id: (getCookie('sub_id') as string) ?? '',
                new_customer: getCookie('new_customer_status') ?? '',
                userPhoneNumber: hashPhoneNumber(userInfo.phone)
              });
              await router.push('/orders');
              removeItemFromStorage(ACTIVE_ORDER_STORAGE_KEY);
            }
          }
        } else {
          await router.push('/auth', undefined, {
            shallow: true
          });
          prevRoute?.setPrevious(router.asPath);
        }
      }
    } catch (e) {
      Sentry.captureException(e);
      logger.error(e, 'submit', {
        action: 'create-order'
      });
    }
  }, [cpfExperiment]);
  return <>
            {/* <OrderFormBackgroundImg> */}
            {/*     <Image src={'/assets/images/bg_hero2.jpeg'} width={7680} height={5120} layout='responsive' /> */}
            {/* </OrderFormBackgroundImg> */}

            <>
                <>
                    <Form<IOrderValues> keepDirtyOnReinitialize initialValues={{
          type: rideType ?? 'private',
          trip_type: 'one_way',
          passengers_count: passengers ?? 1,
          // departure_time: roundToNearestMinutes(addMinutes(new Date(2023, 2, 24, 20, 16), 16), {
          //     nearestTo: 15,
          //     roundingMethod: 'ceil',
          // }),
          departure_time: departureTime ? addMinutes(roundToNearest15Minutes(departureTime), 15) : addMinutes(roundToNearest15Minutes(new Date()), 15),
          city_from_id: null,
          city_to_id: null,
          currency_code: '',
          price: '',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }} mutators={{
          changeCities: async (args, state, utils) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const from = state.formState.values?.city_from_id;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const to = state.formState.values?.city_to_id;
            if (from || to) {
              utils.changeValue(state, 'city_from_id', () => to);
              utils.changeValue(state, 'city_to_id', () => from);
              if (to?.value) {
                await getMinMaxPrice(to.value, from?.value);
                utils.changeValue(state, 'currency_code', () => to?.data?.currency_code);
              }
            }
          }
        }} render={({
          handleSubmit
        }) => <>
                                <WrappedForm minMaxPrice={minMaxPrice} getMinMaxPrice={getMinMaxPrice} getRecPrice={getRecPrice} autofilledCities={autofilledCities} handleSubmit={handleSubmit} isLoading={requestOrder.isLoading} getCityAutocomplete={getCityAutocomplete} />
                            </>} onSubmit={onSubmit} />
                </>
            </>
        </>;
};
export default StaticOrderForm;