import {
    BottomSheet,
    BottomSheetBody,
    BottomSheetCloseIcon,
    BottomSheetFooter,
    BottomSheetHeader,
    BottomSheetHeaderPostfix,
} from '@indriver/nova';
import Button from 'shared/ui/button/button';
import React, { FC, RefObject, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useDisableBodyScroll } from 'shared/hooks';
import { MobilePassengerWrapper } from './order-form.style';
import { InputCounter } from './input-counter';

interface IMobileCounterProps {
    visibleCounter: boolean;
    setVisibleCounter: (value: boolean) => void;
    radioType: string;
    setPassengersCounter: (value: number) => void;
    inputRef: RefObject<HTMLDivElement>;
    value: number;
    dir: 'ltr' | 'rtl';
}

export const MobileInputCounter: FC<IMobileCounterProps> = ({
    visibleCounter,
    setVisibleCounter,
    radioType,
    setPassengersCounter,
    inputRef,
    value,
    dir = 'ltr',
}) => {
    const [count, setCount] = useState(() => value);

    useEffect(() => {
        if (visibleCounter) {
            setCount(value);
        }
    }, [visibleCounter]);
    // useEffect(() => {
    //     if (visibleCounter) {
    //         window.document.body.style.overflow = 'hidden';
    //     } else {
    //         window.document.body.style.overflow = 'unset';
    //     }
    // }, [visibleCounter]);

    useDisableBodyScroll(visibleCounter);

    const { t } = useTranslation();
    return (
        <MobilePassengerWrapper dir={dir} id='root1' visible={visibleCounter}>
            <BottomSheet
                position='fixed'
                rootSelector='#mobile-city-root'
                opened={visibleCounter}
                track='mouse'
                onStartClose={() => setVisibleCounter(false)}>
                <BottomSheetHeader
                    dir={dir}
                    title={radioType === 'private' ? t('order.passengers') : t('order.number_of_seats')}>
                    <BottomSheetHeaderPostfix dir={dir} aria-label='Закрыть' onClick={() => setVisibleCounter(false)}>
                        <BottomSheetCloseIcon />
                    </BottomSheetHeaderPostfix>
                </BottomSheetHeader>
                <BottomSheetBody>
                    <div style={{ padding: '16px' }}>
                        <InputCounter
                            dir={dir}
                            divRef={inputRef}
                            visible={visibleCounter}
                            value={count}
                            onClickPlus={(newValue) => setCount(newValue)}
                            onClickMinus={(newValue) => setCount(newValue)}
                        />
                    </div>
                </BottomSheetBody>
                <BottomSheetFooter style={{ paddingBottom: 16 }}>
                    <Button
                        size='l'
                        text='order.button_ready'
                        onClick={() => {
                            setPassengersCounter(count);
                            setVisibleCounter(false);
                        }}
                    />
                </BottomSheetFooter>
            </BottomSheet>
        </MobilePassengerWrapper>
    );
};
