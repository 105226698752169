import Text from 'shared/ui/text';
import React, { FC } from 'react';
import { variables } from '@indriver/yrel';
import { Trans } from 'next-i18next';
import { HintRecommendedPriceWrapper } from 'features/hint-recommended-price/hint-recommended-price.style';

interface IHintRecPriceProps {
    isRecPrice: boolean;
    currency: string;
    recPriceValue: number;
    onSetRecPrice: () => void;
}

const HintRecommendedPrice: FC<IHintRecPriceProps> = ({ isRecPrice, recPriceValue, currency, onSetRecPrice }) => {
    return (
        <HintRecommendedPriceWrapper>
            {isRecPrice ? (
                <Text
                    text='order.price_can_be_adjusted'
                    style={{
                        color: variables['text-and-icon-secondary'],
                    }}
                />
            ) : (
                !!recPriceValue && (
                    <Trans
                        style={{
                            color: variables['text-and-icon-secondary'],
                        }}
                        i18nKey='order.recommended_price_is'
                        values={{ price: `${recPriceValue} ${currency}` }}
                        components={[
                            // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                            <span
                                key='link'
                                style={{
                                    cursor: 'pointer',
                                    color: variables['text-and-icon-accent'],
                                }}
                                onClick={onSetRecPrice}
                            />,
                        ]}
                    />
                )
            )}
        </HintRecommendedPriceWrapper>
    );
};

export default HintRecommendedPrice;
