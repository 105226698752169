import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';

const useIsRTL = () => {
    const { i18n } = useTranslation();
    const [direction, setDirection] = useState(() => i18n.dir());
    const [isRTL, setIsRTL] = useState(() => i18n.dir() === 'rtl');
    const i18nDir = i18n.dir();
    useEffect(() => {
        const dir = i18n.dir();
        setIsRTL(dir === 'rtl');
        setDirection(dir);
    }, [i18n.language, i18nDir]);

    return { isRTL, direction };
};

export default useIsRTL;
