import { RefObject } from 'react';
import useEventListener from './useEventListener';

type Handler = (event: MouseEvent | TouchEvent) => void;

export default function useOnClickOutside<T extends HTMLElement = HTMLElement>(
    ref: RefObject<T>,
    handler: Handler,
    mouseEvent: 'mousedown' | 'mouseup' | 'touchstart' | 'touchend' = 'mousedown',
): void {
    useEventListener(mouseEvent, (event: MouseEvent | TouchEvent) => {
        const el = ref?.current;
        if (!el || el.contains(event.target as Node)) {
            return;
        }

        handler(event);
    });
}
