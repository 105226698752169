import { Input, TextFieldCell } from '@indriver/nova';
import { FC, memo } from 'react';
import { IInputProps } from '@indriver/nova-core';
import { OrderInputCss, OrderInputWrapper, TextFieldCellWrapper } from './order-form.style';

interface IOrderInputProps {
    isInputButton?: boolean;
    onClickCell?: () => void;
    small?: boolean;
    last?: boolean;
    value: string;
    dir: 'ltr' | 'rtl';
}

export const OrderInput: FC<IOrderInputProps & IInputProps> = memo(({ isInputButton = false, ...props }) => {
    return (
        <OrderInputWrapper value={props.value} small={props.small} last={props.last} className={props.className}>
            {props.value && <span className='placeholder'>{props.placeholder}</span>}
            {isInputButton ? (
                <TextFieldCellWrapper>
                    <TextFieldCell
                        dir={props.dir}
                        id={'pickerId'}
                        value={props.value}
                        className={OrderInputCss}
                        onClick={props.onClickCell}
                    />
                </TextFieldCellWrapper>
            ) : (
                <Input {...props} className={OrderInputCss} />
            )}

            {props.children}
        </OrderInputWrapper>
    );
});
