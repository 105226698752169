import { useEffect, useRef } from 'react';

const useDisableBodyScroll = (open: boolean) => {
    const scrollPosition = useRef(0);
    useEffect(() => {
        if (!open || document.body.style.position === 'fixed') {
            return undefined;
        }
        scrollPosition.current = window.scrollY;
        document.body.style.top = `-${window.scrollY}px`;
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }, 300);

        return () => {
            document.body.style.overflowY = 'initial';
            document.body.style.overflow = 'initial';
            document.body.style.position = 'initial';
            document.body.style.top = '';
            window.scrollTo(0, scrollPosition.current);
        };
    }, [open]);
};

export default useDisableBodyScroll;
