import { Input, IInputProps } from '@indriver/nova';
import { FC, memo, ReactNode } from 'react';
import { OrderInputCss, OrderInputValue, OrderInputWrapper } from './order-form.style';

interface IOrderInputProps {
    small?: boolean;
    last?: boolean;
    value: string | number;
    suffix: string | ReactNode;
}

export const OrderInputNumber: FC<IOrderInputProps & IInputProps> = memo(({ ...props }) => {
    return (
        <OrderInputWrapper value={props.value} small={props.small} last={props.last}>
            {props.value && <span className='placeholder'>{props.placeholder}</span>}
            <Input {...props} className={OrderInputCss} />
            {props.suffix && props.value && (
                <OrderInputValue>
                    <span>{props.value}</span> {props.suffix}
                </OrderInputValue>
            )}
        </OrderInputWrapper>
    );
});
