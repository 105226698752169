import { StickyButtonContainer } from 'widgets/static-order-form/ui/order-form.style';
import Button from 'shared/ui/button';
import React, { FC, useRef } from 'react';
import { useIntersection } from 'shared/hooks/use-intersection';
import { IExtendedButtonProps } from 'shared/ui/button/button';
import useWindowSize from 'shared/hooks/useWindowSize';
import useIsRTL from '../../../shared/hooks/useIsRTL';

const StickyButton: FC<IExtendedButtonProps> = (props) => {
    const { direction } = useIsRTL();
    const { width } = useWindowSize();
    const buttonRef = useRef(null);
    const isInViewport = useIntersection(buttonRef);

    return (
        <>
            <Button ref={buttonRef} dir={direction} {...props} />
            {!isInViewport && width <= 520 && (
                <StickyButtonContainer>
                    <Button {...props} />
                </StickyButtonContainer>
            )}
        </>
    );
};

export default StickyButton;
