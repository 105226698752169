import React, { useEffect, useState } from 'react';

export const useIntersection = (ref: React.RefObject<HTMLElement>) => {
    const [isIntersecting, setIsIntersecting] = useState(false);

    // const observer = useMemo(
    //     () => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting), { threshold: 1 }),
    //     [],
    // );

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting), {
            threshold: 1,
        });
        if (ref.current) observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref]);

    return isIntersecting;
};
