import { FC } from 'react';
import Button from 'shared/ui/button/button';
import {
    BottomHeaderStyle,
    ModalHeader,
    ModalText,
    ModalTitle,
    ModalWrapper,
    TollsMobileModalWrapper,
} from 'features/ride-with-tolls/ride-with-tolls.style';
import { BottomSheet, BottomSheetBody, BottomSheetFooter, BottomSheetHeader, Group, Modal } from '@indriver/nova';
import useWindowSize from 'shared/hooks/useWindowSize';
import useIsRTL from 'shared/hooks/useIsRTL';
import { Text } from 'shared/ui';
import { useTranslation } from 'next-i18next';
import { ITranslate } from 'entities/ITranslate';
import { sendAnalyticsEvent } from 'shared/lib/send-analytics-event';

interface IRideWithTollsProps {
    title: ITranslate | string;
    text: ITranslate | string;
    opened: boolean;
    onClose: () => void;
    onContinue: () => void;
}

const RideWithTollsModal: FC<IRideWithTollsProps> = ({ title, text, opened, onContinue, onClose }) => {
    const { width } = useWindowSize();
    const { direction } = useIsRTL();
    const { t } = useTranslation();
    if (width <= 960) {
        return (
            <>
                <TollsMobileModalWrapper dir={direction} id='tolls-modal-root' visible={opened} />

                <BottomSheet
                    dir={direction}
                    zIndex={99999}
                    position='fixed'
                    rootSelector='#tolls-modal-root'
                    opened={opened}
                    track='mouse'>
                    <BottomSheetHeader title={t(title)} className={BottomHeaderStyle} />
                    <BottomSheetBody style={{ padding: '16px 16px 8px' }}>
                        <Text text={text} />
                    </BottomSheetBody>
                    <BottomSheetFooter style={{ paddingBottom: 16, gap: 12 }}>
                        <Button
                            size='m'
                            text={'auth.continue'}
                            onClick={() => {
                                onContinue();
                                sendAnalyticsEvent('web.intercity.psg.tolls_continue.click');
                            }}
                        />
                        <Button
                            design={'secondary'}
                            size='m'
                            text={'auth.back'}
                            onClick={() => {
                                onClose();
                                sendAnalyticsEvent('web.intercity.psg.tolls_back.click');
                            }}
                        />
                    </BottomSheetFooter>
                </BottomSheet>
            </>
        );
    }

    return (
        <Modal
            padding='56px'
            opened={opened}
            rootSelector='#modal-root'
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            title={<ModalHeader />}
            closeable={false}
            onClose={onClose}>
            <ModalWrapper>
                <ModalTitle>{t(title)}</ModalTitle>
                <ModalText>{t(text)}</ModalText>
                <Group direction={'column'} gap={16}>
                    <Button
                        size='l'
                        text={'auth.continue'}
                        onClick={() => {
                            onContinue();
                            sendAnalyticsEvent('web.intercity.psg.tolls_continue.click');
                        }}
                    />
                    <Button
                        design={'secondary'}
                        size='l'
                        text={'auth.back'}
                        onClick={() => {
                            onClose();
                            sendAnalyticsEvent('web.intercity.psg.tolls_back.click');
                        }}
                    />
                </Group>
            </ModalWrapper>
        </Modal>
    );
};

export default RideWithTollsModal;
